import { render, staticRenderFns } from "./pageHeader.vue?vue&type=template&id=156c6794&scoped=true&"
import script from "./pageHeader.js?vue&type=script&lang=js&"
export * from "./pageHeader.js?vue&type=script&lang=js&"
import style0 from "./pageHeader.scss?vue&type=style&index=0&id=156c6794&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "156c6794",
  null
  
)

export default component.exports