/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-18 17:28:18
 * @LastEditors: Arvin
 * @LastEditTime: 2020-12-03 11:28:12
 */
import moment from 'moment'
import { createNamespacedHelpers, mapGetters as mapRootGetters } from 'vuex'
import { getNormalWeek } from '@/plugins/utils'

const { mapGetters, mapActions } = createNamespacedHelpers('Operate/Datapackage/Crowdattribute')

export default {
  name: 'pageHeader',
  data () {
    return {
      tabs: [
        {
          name: '图表',
          icon: 'ic_chart',
          value: 1
        },
        {
          name: '列表',
          icon: 'ic_list',
          value: 2
        }
      ],
      typeList: [
        {
          name: '地域',
          id: 1
        }
      ]
    }
  },
  computed: {
    // 当前展示列表还是图表
    ...mapGetters([
      'activeTab',
      'queryForm',
      'pageTitle'
    ]),

    ...mapRootGetters([
      'enum_map'
    ]),

    // 维度选项
    dimensionOptions () {
      return this.enum_map.dimension_type || []
    },

    // 第一维度
    firstDimension () {
      return this.dimensionOptions[0] || {}
    }
  },
  watch: {
    activeTab (newVal) {
      const self = this
      const query = {}
      // 图表
      if (newVal === 1) {
        query.dimension = ''
      }
      // 列表
      if (newVal === 2) {
        query.dimension = self.firstDimension.id || ''
      }
      if (!(self.queryForm.analysis_type - 1)) {
        const [startDate, endDate] = getNormalWeek(true)
        query._date = [new Date(startDate), new Date(endDate)]
        query.start_date = startDate
        query.end_date = endDate
      }
      this.getCrowdattributeList(query)
    }
  },
  methods: {

    ...mapActions([
      'updateQueryForm',
      'updateActiveTab',
      'getCrowdattributeList' // 获取图表数据
    ]),

    /**
     * 修改选择时间
     */
    async dateChange (newVal) {
      if (Array.isArray(newVal) && newVal[0]) {
        let [startDate, endDate] = newVal
        startDate = moment(startDate).format('YYYY-MM-DD')
        endDate = moment(endDate).format('YYYY-MM-DD')
        await this.updateQueryForm({
          start_date: startDate,
          end_date: endDate
        })
      } else if (newVal === null) {
        await this.updateQueryForm({
          start_date: '',
          end_date: ''
        })
      }
      // 更新图表
      this.getCrowdattributeList()
    },

    /**
     * 切换投放平台
     */
    dimensionChange () {
      // 更新图表
      this.getCrowdattributeList()
    }
  }
}
